// Plane.orthoPoint was removed in R87, the polyfilled version for R125 was taken from R86
// https://github.com/mrdoob/three.js/blob/r86/src/math/Plane.js#L117-L124
export const planeOrthoPoint = (plane, point, optionalTarget ) => {
    /// #if threejsVersion == 'R71'
    {
        return plane.orthoPoint(point, optionalTarget);
    }
    /// #elif threejsVersion == 'R125'
    {
        const perpendicularMagnitude = plane.distanceToPoint( point );
        const result = optionalTarget || new THREE.Vector3();
        return result.copy( plane.normal ).multiplyScalar( perpendicularMagnitude );
    }
    /// #endif
};

export const vector3ApplyProjection = (v, m) => {
    /// #if threejsVersion == 'R71'
    {
        return v.applyProjection(m);
    }
    /// #elif threejsVersion == 'R125'
    {
        return v.applyMatrix4(m);
    }
    /// #endif
}
